import React from 'react';
import {PerformanceSection,PerformanceHeadingWrapper,PerformanceSectionHeading,
    PerformanceHolder,PerformanceCountLayout
} from './performance.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';

const Performance =() => {
    const JSONData  = useStaticQuery(graphql`
        query{
            saasJson {
                Performance {
                    DelayConstant
                    PerformanceSectionHeading
                    PerformanceHeadingPara
                    Row{
                        PerformanceCount
                        PerformanceDetails
                    }
                }
            }
        }
    `);
    return (
        <PerformanceSection id="performanceContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <PerformanceHeadingWrapper>
                            <Fade bottom delay={1*JSONData.saasJson.Performance.DelayConstant}>
                                <PerformanceSectionHeading>{JSONData.saasJson.Performance.PerformanceSectionHeading}</PerformanceSectionHeading>
                            </Fade>
                            <Fade bottom delay={2*JSONData.saasJson.Performance.DelayConstant}>
                                <p>
                                {JSONData.saasJson.Performance.PerformanceHeadingPara}
                                </p>
                            </Fade>
                        </PerformanceHeadingWrapper>
                    </Col>
                </Row>
                <Row>
                {
                    JSONData.saasJson.Performance.Row.map((item,idx) => {
                    return <Col md="3" sm="6">
                        <Fade bottom delay={(idx+1)*JSONData.saasJson.Performance.DelayConstant}>
                            <PerformanceHolder>
                                <PerformanceCountLayout>
                                    <h2>{item.PerformanceCount}</h2>
                                </PerformanceCountLayout>
                                <p>
                                    {item.PerformanceDetails}
                                </p>
                            </PerformanceHolder>
                        </Fade>
                    </Col>
                    })
                }
                </Row>
            </Container>
        </PerformanceSection>
    );
}

export default Performance;
