import React,{Component} from 'react';
import {BannerWrapper,
    BannerContents,
    BannerContentLeft,BannerVideoBtn,
    BannerContentRight,
    BannerBtn,BannerBtnsWrapper,PlayIconCustom
} from './banner.style'
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade';
import { StaticQuery, graphql } from 'gatsby';
import LoginDialog from '../LoginDialog';
import { GatsbyImage } from "gatsby-plugin-image";

class Banner extends Component{
    constructor(props){
        super(props);
        this.state = {modal: false}
    }

    handleClose = () => this.setState({ modal: !this.state.modal });

    render(){
        return (
            <BannerWrapper id="homeContainer">
                <Container>
                    <BannerContents>
                        <BannerContentLeft>
                            <Fade delay={1*this.props.saasJson.Banner.DelayConstant}>
                                <h1>{this.props.saasJson.Banner.BannerHeading}</h1>
                                <h3>{this.props.saasJson.Banner.BannerSubHeading}</h3>
                                <p>{this.props.saasJson.Banner.BannerPara}</p>
                            </Fade>
                            <Fade delay={2*this.props.saasJson.Banner.DelayConstant}>
                                <BannerBtnsWrapper>
                                    <BannerBtn onClick={this.handleClose}>{this.props.saasJson.Banner.BannerBtn}</BannerBtn>
                                    <BannerVideoBtn href="#"><PlayIconCustom size={45}/>{this.props.saasJson.Banner.BannerVideoBtnTxt}</BannerVideoBtn>
                                </BannerBtnsWrapper>
                            </Fade>
                        </BannerContentLeft>


                        <BannerContentRight>
                            <Fade delay={3*this.props.saasJson.Banner.DelayConstant}>
                                <GatsbyImage
                                    image={this.props.saasJson.Banner.BannerImage.childImageSharp.gatsbyImageData}
                                    alt="" />
                            </Fade>
                        </BannerContentRight>

                    </BannerContents>
                    <LoginDialog modalFlag = {this.state.modal} handleClose ={this.handleClose}/>
                </Container>
            </BannerWrapper>
        );
    }
}

const BannerSection = () => (
    <StaticQuery
        query={graphql`{
  saasJson {
    Banner {
      DelayConstant
      BannerHeading
      BannerSubHeading
      BannerPara
      BannerBtn
      BannerVideoBtnTxt
      BannerImage {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`}
        render={(data) => (
            <Banner saasJson={data.saasJson}/>
        )}
    />
)
export default BannerSection;
