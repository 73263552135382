import styled from 'styled-components';
import {device} from '../Common/device'
import {SocialFacebook} from '@styled-icons/typicons/SocialFacebook'
import {SocialTwitter} from '@styled-icons/typicons/SocialTwitter'
import {SocialLinkedin} from '@styled-icons/typicons/SocialLinkedin'
import {SocialInstagram} from '@styled-icons/typicons/SocialInstagram'

export const FooterSection = styled.footer`
    width:100%;
    background:#5f27fa;
    padding:60px 0px 0px;

    @media ${device.tablet} {
        padding:60px 10px 0px;
    }
`;

export const FooterCol = styled.div`
    padding:20px 0px;

    ul{
        margin-left:0px;
    }

    a{
        text-decoration:none;
        color:#fff;

        :hover{
            text-decoration:underline;
        }
    }

    img{
        max-width: 170px;

        @media ${device.laptop} {
            max-width: 140px;
        }

        @media ${device.tablet} {
            max-width: 170px;
        }
    }

    h5{
        color:#fff;
        margin-bottom:25px;
    }
    p{
        color:#fff;
        margin-bottom:5px;
    }
`;



export const FooterLi = styled.li`
    list-style:none;
    margin-bottom:6px;
`;

export const FooterSocial = styled.a`
    margin-right:5px;
`;

export const FooterAddWrapper = styled.div`
    display:flex;
    align-items:start;
    margin-bottom:10px;

    img{
        margin-bottom: 0px;
        width:35px;
        flex-shrink:0;
    }
    p{
        color:#fff;
        margin-left:10px;
        margin-bottom:0px;
    }
`;


export const BottomFooterWrapper = styled.section`
    width:100%;
    padding:20px 0px;
    background:#5f27fa;
`;

export const BottomFooterPara = styled.p`
    color:#fff;
    margin-bottom:0px;
    padding:10px 0px;
    font-size:15px;

    @media ${device.tablet} {
        text-align:center;
        margin-bottom:10px;
    }
`;

export const BottomLink = styled.a`
    font-size:15px;
    margin-bottom:0px;
    padding:10px 0px;
    color:#fff;
    text-decoration:none;

    :hover{
        color:#fff;
        text-decoration:underline;
    }
`;

export const BottomFooterRight = styled.div`
    text-align:right;

    @media ${device.tablet} {
        text-align:center;
    }
`;

export const InstaIcon = styled(SocialInstagram)`
    width: 32px;
    color: #5f27fa;
    padding: 6px;
    border-radius:4px;
    margin: 0px 8px;
    background: #fff;
    transition:all .5s;

    :hover{
        border-radius:100%;
    }
`;

export const LinkedinIcon = styled(SocialLinkedin)`
    width: 32px;
    color: #5f27fa;
    padding: 6px;
    border-radius:4px;
    margin: 0px 8px;
    background: #fff;
    transition:all .5s;

    :hover{
        border-radius:100%;
    }
`;

export const TwitterIcon = styled(SocialTwitter)`
    width: 32px;
    color: #5f27fa;
    padding: 6px;
    border-radius:4px;
    margin: 0px 8px;
    background: #fff;
    transition:all .5s;

    :hover{
        border-radius:100%;
    }
`;

export const FbIcon = styled(SocialFacebook)`
    width: 32px;
    color: #5f27fa;
    padding: 6px;
    border-radius:4px;
    margin: 0px 8px;
    background: #fff;
    transition:all .5s;

    :hover{
        border-radius:100%;
    }
`;
