import React from 'react';
import {OurProductSection,ProductsHeadingWrapper,
    ProductsHeading,ProductsInner,ProductsRightImgSecond,ProductsLeftImgFirst,
    ProductsLeftImgSecond,
    ProductsLeft,ProductsRight,ProductsRightImgFirst,
    ProductsBtn,ProductsRowReverse,ProductsRow
} from './OurProduct.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';

const Products  = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            saasJson {
                Products {
                    DelayConstant
                    ProductsHeading
                    ProductsSubHeading
                    ProductsRow1{
                        ProductsRightImgFirst
                        ProductsRightImgSecond
                        ProductsLeftHeading
                        ProductsLeftPara
                        ProductsBtn
                    }
                    Row{
                        ProductsLeftImgFirst
                        ProductsLeftImgSecond
                        ProductsLeftHeading
                        ProductsLeftPara
                        ProductsBtn
                    }
                    ProductsRow2{
                        ProductsRightImgFirst
                        ProductsRightImgSecond
                        ProductsLeftHeading
                        ProductsLeftPara
                        ProductsBtn
                    }
                }
            }
        }
    `);

    return (
        <OurProductSection id="productsContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <ProductsHeadingWrapper>
                            <Fade bottom delay={1*JSONData.saasJson.Products.DelayConstant}>
                                <ProductsHeading>{JSONData.saasJson.Products.ProductsHeading}</ProductsHeading>
                            </Fade>
                            <Fade bottom delay={2*JSONData.saasJson.Products.DelayConstant}>
                                <h6>{JSONData.saasJson.Products.ProductsSubHeading}</h6>
                            </Fade>
                        </ProductsHeadingWrapper>
                    </Col>
                </Row>

                <ProductsInner>

                    <ProductsRowReverse padddingBottom={"80px"}>
                        <Col lg="7">
                            <Fade right delay={1*JSONData.saasJson.Products.DelayConstant}>
                                <ProductsRight>
                                    <ProductsRightImgFirst src={JSONData.saasJson.Products.ProductsRow1.ProductsRightImgFirst} alt=""/>
                                    <ProductsRightImgSecond src={JSONData.saasJson.Products.ProductsRow1.ProductsRightImgSecond} alt=""/>
                                </ProductsRight>
                            </Fade>
                        </Col>

                        <Col lg="5">
                            <ProductsLeft>
                                <Fade left delay={1*JSONData.saasJson.Products.DelayConstant}>
                                    <h3>
                                    {JSONData.saasJson.Products.ProductsRow1.ProductsLeftHeading}
                                    </h3>
                                </Fade>
                                <Fade left delay={1*JSONData.saasJson.Products.DelayConstant}>
                                    <p>
                                    {JSONData.saasJson.Products.ProductsRow1.ProductsLeftPara}
                                    </p>
                                </Fade>
                                <Fade left delay={1*JSONData.saasJson.Products.DelayConstant}>
                                    <ProductsBtn href="#">
                                    {JSONData.saasJson.Products.ProductsRow1.ProductsBtn}
                                    </ProductsBtn>
                                </Fade>
                            </ProductsLeft>
                        </Col>
                    </ProductsRowReverse>

                    <ProductsRow>
                        <Col lg="7">
                        <Fade left delay={1*JSONData.saasJson.Products.DelayConstant}>
                            <ProductsRight style={{textAlign:"left"}}>
                                <ProductsLeftImgFirst src={JSONData.saasJson.Products.Row.ProductsLeftImgFirst} alt=""/>
                                <ProductsLeftImgSecond src={JSONData.saasJson.Products.Row.ProductsLeftImgSecond} alt=""/>
                            </ProductsRight>
                            </Fade>
                        </Col>
                        <Col lg="5">
                            <ProductsLeft>
                                <Fade right delay={1*JSONData.saasJson.Products.DelayConstant}>
                                    <h3>
                                    {JSONData.saasJson.Products.Row.ProductsLeftHeading}
                                    </h3>
                                </Fade>
                                <Fade right delay={2*JSONData.saasJson.Products.DelayConstant}>
                                    <p>
                                    {JSONData.saasJson.Products.Row.ProductsLeftPara}
                                    </p>
                                </Fade>
                                <Fade right delay={3*JSONData.saasJson.Products.DelayConstant}>
                                    <ProductsBtn href="#">
                                    {JSONData.saasJson.Products.Row.ProductsBtn}
                                    </ProductsBtn>
                                </Fade>
                            </ProductsLeft>
                        </Col>
                    </ProductsRow>

                    <ProductsRowReverse padddingBottom={"0px"}>
                        <Col lg="7">
                            <Fade right delay={1*JSONData.saasJson.Products.DelayConstant}>
                                <ProductsRight>
                                    <ProductsRightImgFirst src={JSONData.saasJson.Products.ProductsRow2.ProductsRightImgFirst} alt=""/>
                                    <ProductsRightImgSecond src={JSONData.saasJson.Products.ProductsRow2.ProductsRightImgSecond} alt=""/>
                                </ProductsRight>
                            </Fade>
                        </Col>
                        <Col lg="5">
                            <ProductsLeft>
                                <Fade left delay={1*JSONData.saasJson.Products.DelayConstant}>
                                    <h3>
                                    {JSONData.saasJson.Products.ProductsRow2.ProductsLeftHeading}
                                    </h3>
                                </Fade>
                                <Fade left delay={1*JSONData.saasJson.Products.DelayConstant}>
                                    <p>
                                    {JSONData.saasJson.Products.ProductsRow2.ProductsLeftPara}
                                    </p>
                                </Fade>
                                <Fade left delay={1*JSONData.saasJson.Products.DelayConstant}>
                                    <ProductsBtn href="#">
                                    {JSONData.saasJson.Products.ProductsRow2.ProductsBtn}
                                    </ProductsBtn>
                                </Fade>
                            </ProductsLeft>
                        </Col>
                    </ProductsRowReverse>
                </ProductsInner>
            </Container>
        </OurProductSection>
    );
}
export default Products;
