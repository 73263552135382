import styled from 'styled-components';
import {device} from '../Common/device'
import {QuoteAltLeft} from '@styled-icons/boxicons-solid/QuoteAltLeft';

export const TestimonialsSection = styled.section`
    padding:100px 0px;
    position:relative;
    background: #f9fafc;

    @media ${device.tablet} {
        display:block;
        padding:80px 10px;
    }
`;

export const HeadingWrapper = styled.div`
    margin-bottom:75px;

    @media ${device.tablet} {
        margin-bottom:60px;
    }
    p{
        text-align:center;
        color:#565759;
        font-weight:400;
        margin-bottom:0px;
        font-size:16px;
    }
`;

export const SliderOuterWrapper = styled.div`
    margin-right:-30px;
    margin-left:-30px;

    @media ${device.tablet} {
        margin-right:-25px;
        margin-left:-25px;
    }
`;

export const CardOuterWrapper = styled.div`
    outline:0;
`;

export const Card = styled.div`
    background:#fff;
    margin:30px; // Equal to negative margin in SliderOuterWrapper
    padding: 25px;
    border-radius:10px;
    box-shadow: 0px 8px 30px 0px #d0d0d0;
    position:relative;

    @media ${device.tablet} {
        margin:25px; // Equal to negative margin in SliderOuterWrapper
        box-shadow: 0px 8px 20px 0px #d0d0d0;
    }

    ::after{
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 30px 42px 0 1px;
        border-color: #ffffff transparent transparent transparent;
        position: absolute;
        bottom: -20px;
    }

    p{
        margin-bottom: 0px;
    }
`;

export const LeftQuote = styled(QuoteAltLeft)`
    width: 50px;
    color:#565658;
    margin-bottom:10px;
`;


export const CardProfileLayout = styled.div`
    display:flex;
    align-items:center;
    margin-top: 40px;
    margin-left:30px;
`;

export const ProfilePicLayout = styled.div`

    .ProfilePic{
        margin-right:10px;
        border-radius:100%;
        width:50px;

        @media ${device.tablet} {
            width:40px;
        }
    }
`;

export const ProfileNameLayout = styled.div`
    h6{
        color:#3a3a3a;
        margin-bottom:5px;
        line-height:1;
        text-align:left;

        @media ${device.tablet} {
            font-size:18px;
            line-height:12px;
        }
    }
    p{
        margin-bottom:0px;
        line-height:1;
    }
`;


export const NextPrev = styled.div`
    text-align:center;
    line-height:0px;
    margin-top:30px;
`;

export const ImgButton = styled.button`
    line-height:0px;
    padding-bottom:0px;
    border:none;
    background:none;
    outline:none;

    :focus{
        outline:0;
    }
`;

export const PrevImg = styled.img`
    margin:0px 5px;
    width: 50px;
    cursor:pointer;
    transition:all .4s;

    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }
`;

export const NextImg = styled.img`
    margin:0px 5px;
    width: 50px;
    cursor:pointer;
    transition:all .4s;

    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }
`;
