import styled from 'styled-components';
import {SectionHeading} from '../Common/common.style';
import {PlusCircle} from '@styled-icons/boxicons-regular/PlusCircle';
import {MinusCircle} from '@styled-icons/boxicons-regular/MinusCircle';
import {device} from '../Common/device';

export const FaqSection = styled.section`
    padding:100px 0px;

    @media ${device.tablet} {
        padding:80px 10px;
    }
`;

export const FaqHeadingWrapper = styled.div`
    margin-bottom:75px;

    @media ${device.tablet} {
        margin-bottom:60px;
    }

    p{
        color:#565759;
        margin-bottom: 0;
        font-size: 16px;
        text-align:center;
    }
`;

export const FaqSectionHeading = styled(SectionHeading)`
`;

export const FaqAccordianWrapper = styled.div`
    max-width:900px;
    margin:auto;

`;

export const AccordianRow = styled.div`
    border-bottom:1px solid #ddd;
`;

export const Accordian = styled.div`
    overflow: hidden;
`;

export const AccordianTitleCol = styled.div`
    display:flex;
    align-items:center;
    background:#fff;
    transition: all .5s;

    :hover{
        background:#f5f5f5;
        transition: all .5s;
    }
    h6{
        padding: 15px 10px 15px 20px;
        overflow:hidden;
        margin-bottom:0px;
        cursor:pointer;
        flex-grow: 1;

        @media ${device.tablet} {
        }
    }
`;



export const AccordianContentCol = styled.div`
    padding: 0px 20px 15px 20px;
`;

export const AccordianContent = styled.div`
    p{
        color: #565759;
        margin-bottom:0px;
        padding:10px 0px;
    }
`;

export const PlusIcon = styled(PlusCircle)`
    color: #5f27fa;
    width: 25px;
    margin-right: 10px;
    flex-shrink:0;
`;

export const MinusIcon = styled(MinusCircle)`
    color: #5f27fa;
    width: 25px;
    margin-right: 10px;
    flex-shrink:0;
`;
