import React from 'react';
import {KeyFeaturesSection,KeyFeaturesHeading,KeyFeaturesInner,CustomRow,KeyFeaturesRightLi,
    KeyFeaturesHeadingWrapper,
    KeyFeaturesRightBtn,KeyFeaturesRightUl,KeyFeaturesRight,KeyFeaturesLeft,KeyFeaturesLiImgCol,CheckIcon,
    KeyFeaturesLiContentCol} from './keyfeatures.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

const KeyFeatures = () => {
    const JSONData  = useStaticQuery(graphql`{
  saasJson {
    KeyFeatures {
      DelayConstant
      KeyFeaturesHeading
      KeyFeaturesPara
      KeyFeaturesLeftImg {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      KeyFeaturesRightUl {
        KeyFeaturesRightLih3
        KeyFeaturesRightLipara
      }
      KeyFeaturesRightBtn
    }
  }
}
`);
    return (
        <KeyFeaturesSection id="KeyFeaturesContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <KeyFeaturesHeadingWrapper>
                            <Fade bottom delay={1*JSONData.saasJson.KeyFeatures.DelayConstant}>
                            <KeyFeaturesHeading>{JSONData.saasJson.KeyFeatures.KeyFeaturesHeading}</KeyFeaturesHeading>
                            </Fade>
                            <Fade bottom delay={2*JSONData.saasJson.KeyFeatures.DelayConstant}>
                            <h6>{JSONData.saasJson.KeyFeatures.KeyFeaturesPara}</h6>
                            </Fade>
                        </KeyFeaturesHeadingWrapper>
                    </Col>
                </Row>
                <KeyFeaturesInner>
                    <CustomRow>
                        <Col lg="6" md="12">
                            <Fade left delay={1*JSONData.saasJson.KeyFeatures.DelayConstant}>
                                <KeyFeaturesLeft>
                                    <GatsbyImage
                                        image={JSONData.saasJson.KeyFeatures.KeyFeaturesLeftImg.childImageSharp.gatsbyImageData}
                                        className="KeyFeaturesLeftImg"
                                        alt="" />
                                </KeyFeaturesLeft>
                            </Fade>
                        </Col>
                        <Col lg="6" md="12">
                            <KeyFeaturesRight>
                                <KeyFeaturesRightUl>
                                {
                                    JSONData.saasJson.KeyFeatures.KeyFeaturesRightUl.map((item,idx) => {
                                        return  <KeyFeaturesRightLi>
                                            <Fade right delay={(idx+1)*JSONData.saasJson.KeyFeatures.DelayConstant}>
                                                <KeyFeaturesLiImgCol>
                                                    <CheckIcon/>
                                                </KeyFeaturesLiImgCol>
                                                <KeyFeaturesLiContentCol>
                                                    <h6>{item.KeyFeaturesRightLih3}</h6>
                                                    <p>{item.KeyFeaturesRightLipara}</p>
                                                </KeyFeaturesLiContentCol>
                                            </Fade>
                                        </KeyFeaturesRightLi>

                                    })
                                }
                                </KeyFeaturesRightUl>
                                <Fade right delay={4*JSONData.saasJson.KeyFeatures.DelayConstant}>
                                    <KeyFeaturesRightBtn href="#">{JSONData.saasJson.KeyFeatures.KeyFeaturesRightBtn}</KeyFeaturesRightBtn>
                                </Fade>
                            </KeyFeaturesRight>
                        </Col>
                    </CustomRow>
                </KeyFeaturesInner>
            </Container>
        </KeyFeaturesSection>
    );
}

export default KeyFeatures;
