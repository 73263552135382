import React from 'react';
import {FeaturesSection,FeaturesLeft,FeaturesRight,FeaturesCard,
FeaturesCardImg,FeaturesLeftBtn,FeaturesRow
} from './features.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';


const Features = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            saasJson {
                Features {
                    DelayConstant
                    FeaturesLeftHeading
                    FeaturesLeftPara
                    FeaturesLeftBtn
                    FeaturesRight{
                        FeaturesCardIcon
                        FeaturesCardHeading
                        FeaturesCardPara
                    }
                }
            }
        }
    `);
    return (
        <FeaturesSection id="featuresContainer">
            <Container>
                <FeaturesRow>
                    <Col md="12" lg="4">
                        <FeaturesLeft>
                            <Fade left delay={1*JSONData.saasJson.Features.DelayConstant}>
                            <h3>{JSONData.saasJson.Features.FeaturesLeftHeading}</h3>
                            </Fade>
                            <Fade left delay={2*JSONData.saasJson.Features.DelayConstant}>
                            <p>{JSONData.saasJson.Features.FeaturesLeftPara}</p>
                            </Fade>
                            <Fade left delay={3*JSONData.saasJson.Features.DelayConstant}>
                            <FeaturesLeftBtn href="#">{JSONData.saasJson.Features.FeaturesLeftBtn}</FeaturesLeftBtn>
                            </Fade>
                        </FeaturesLeft>
                    </Col>
                    <Col md="12" lg="8">
                        <FeaturesRight>
                            <Row>
                            {
                                JSONData.saasJson.Features.FeaturesRight.map((item,idx) => {
                                return <Col lg="6" md="6" sm="12">
                                        <Fade right delay={(idx+1)*JSONData.saasJson.Features.DelayConstant}>
                                        <FeaturesCard>
                                            <FeaturesCardImg>
                                                <img src={item.FeaturesCardIcon} alt=""/>
                                            </FeaturesCardImg>
                                            <h5>{item.FeaturesCardHeading}</h5>
                                            <p>{item.FeaturesCardPara}</p>
                                        </FeaturesCard>
                                        </Fade>
                                    </Col>
                                })
                            }
                            </Row>
                        </FeaturesRight>
                    </Col>
                </FeaturesRow>
            </Container>
        </FeaturesSection>

    );
}

export default Features;
