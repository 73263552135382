import React,{Component} from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {TestimonialsSection,SliderOuterWrapper,CardOuterWrapper,Card,CardProfileLayout,
    ProfilePicLayout,ProfileNameLayout,LeftQuote,
    ImgButton,PrevImg,NextImg,NextPrev,HeadingWrapper} from './testimonials.style';
import {SectionHeading} from "../Common/common.style";
import { StaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";
import { GatsbyImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Testimonials extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1,
                      initialSlide: 1
                    }
                },
                {
                  breakpoint: 575,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                }
            ]
        };

        return (
            <TestimonialsSection id="SliderOuterWrapper">
                <Container>
                    <Row>
                        <Col md="12">
                            <Fade bottom delay={1*this.props.saasJson.Testimonials.DelayConstant}>
                                <HeadingWrapper>
                                    <SectionHeading>{this.props.saasJson.Testimonials.TestimonialHeading}</SectionHeading>
                                    <p>{this.props.saasJson.Testimonials.TestimonialSubHeading}</p>
                                </HeadingWrapper>
                            </Fade>
                        </Col>
                    </Row>
                <Fade bottom delay={1*this.props.saasJson.Testimonials.DelayConstant}>
                    <SliderOuterWrapper>
                        <Slider ref={c => (this.slider = c)} {...settings}>
                        {
                                this.props.saasJson.Testimonials.CardProfileLayout.map((item,idx) => {
                                return (
                                    <CardOuterWrapper>
                                    <Card>
                                        <LeftQuote/>
                                        <p>
                                                {item.Testimonial}
                                        </p>
                                    </Card>
                                    <CardProfileLayout>
                                            <ProfilePicLayout>
                                                <GatsbyImage
                                                    image={item.ProfilePic.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    className="ProfilePic" />
                                            </ProfilePicLayout>
                                            <ProfileNameLayout>
                                                <h6>
                                                    {item.ProfileName}
                                                </h6>
                                                <p>
                                                    {item.Designation}
                                                </p>
                                            </ProfileNameLayout>
                                        </CardProfileLayout>
                                </CardOuterWrapper>
                                );
                                })
                            }
                        </Slider>
                    </SliderOuterWrapper>
                </Fade>
                <NextPrev>
                    <ImgButton onClick={this.previous} aria-label="Prev Button">
                        <PrevImg src={this.props.saasJson.Testimonials.PrevImg} alt=""/>
                    </ImgButton>
                    <ImgButton onClick={this.next} aria-label="Next Button">
                        <NextImg src={this.props.saasJson.Testimonials.NextImg} alt=""/>
                    </ImgButton>
                </NextPrev>
                </Container>
            </TestimonialsSection>
        );
    }
}


const TestimonialsPageSection = () => (
    <StaticQuery
        query={graphql`{
  saasJson {
    Testimonials {
      TestimonialHeading
      TestimonialSubHeading
      CardProfileLayout {
        ProfilePic {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        ProfileName
        Designation
        TestimonailHeading
        Testimonial
      }
      PrevImg
      NextImg
    }
  }
}
`}
        render={(data) => (
            <Testimonials saasJson={data.saasJson}/>
        )}
    />
  )
export default TestimonialsPageSection